/* eslint-disable react/jsx-no-target-blank */
import { PaymentMethod, PaymentMethodDescription } from '@hah/enums';
import { ActionButton, Icon, iconLibrary } from '@hah/shared';
import { OnChangePickSetState } from '@hah/utils';
import * as Sentry from '@sentry/browser';
import { useFormikContext } from 'formik';
import { MutableRefObject, useEffect, useState } from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import { Client } from '../../custom-types/braintree-web-custom/client';
import { ChangeActiveViewPayload, Dropin, PaymentMethodRequestablePayload, PaymentOptionSelectedPayload, UpdatableConfigurationOption, googlePayCreateOptions } from '../../custom-types/braintree-web-drop-in-custom';
import { BookOrderValidationModel } from '../validationSchema';
import { BillingContactInfo } from './BillingContactInfo';
import { HahFormikCheckbox, HahFormikField } from './HahFormikField';
import { QuoteUrgencyBanner } from './QuoteUrgencyBanner';

interface DropinExtended extends Dropin {
    _client: Client;
}
interface googlePayCreateOptionsExtended extends googlePayCreateOptions {
    allowedPaymentMethods: any;
}

type Props = {
    pageVm: models.CheckoutPaymentStepReactViewModel;
    model: models.CheckoutPaymentViewModel;
    onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>;
    isFormSubmitting: boolean;
    btToken: string;
    googleMerchantID: string;
    paymentNonce: MutableRefObject<string>;
    dropinInstance: MutableRefObject<Dropin | undefined>;
};

export const PaymentMethodBtDropInContainer = ({ model, pageVm, onChange, isFormSubmitting, btToken, googleMerchantID, paymentNonce, dropinInstance }: Props) => {
    const { handleSubmit, setFieldValue } = useFormikContext<BookOrderValidationModel>();
    const [paymentRequestable, setPaymentRequestable] = useState(false);
    const logErrorToSentry = (error: Error) => {
        Sentry?.captureException(error, { tags: { customerAuthKey: pageVm.authToken.customerAuthKey, linkedJobsKey: pageVm.authToken.linkedJobsKey, 'grouping.Checkout': true } });
    };

    const mapPaymentMethod = (payloadType: string) => {
        switch (payloadType) {
            case 'PayPalAccount':
            case 'paypal':
            case 'paypalCredit':
                return PaymentMethod.PayPal;
            case 'CreditCard':
            case 'card':
                return PaymentMethod.CreditCard;
            case 'AndroidPayCard':
            case 'googlePay':
                return PaymentMethod.GooglePay;
            case 'ApplePayCard':
            case 'applePay':
                return PaymentMethod.ApplePay;
            default:
                return PaymentMethod.Unknown;
        }
    };

    const handleChangeActiveView = (payload: ChangeActiveViewPayload) => {
        if (payload.newViewId === 'options') {
            setFieldValue('paymentMethod', PaymentMethod.Unknown);
            onChange({ paymentMethod: PaymentMethod.Unknown });
        }
    };

    const handlePaymentOptionSelected = (payload: PaymentOptionSelectedPayload) => {
        const newPaymentMethod = mapPaymentMethod(payload.paymentOption);
        window.BTPaymentType = payload.paymentOption;
        setFieldValue('paymentMethod', newPaymentMethod);
        onChange({ paymentMethod: newPaymentMethod });
    };

    const handlePaymentMethodRequestable = (payload: PaymentMethodRequestablePayload) => {
        const newPaymentMethod = mapPaymentMethod(payload.type);
        setFieldValue('paymentMethod', newPaymentMethod);
        setPaymentRequestable(true);
        onChange({ paymentMethod: newPaymentMethod });
    };

    // if model.PayNow is set to true we need to update BT config with new grand total? Perhaps we can just do this on paymentMethodRequestable and only update the amount for the payment type that is selected?
    useEffect(() => {
        if (!dropinInstance?.current) {
            return;
        }

        const total = (model.payNow && model.payNow == true ? pageVm.payNowGrandTotalNumeric : pageVm.grandTotalNumeric).toString();

        ['paypal', 'paypalCredit', 'googlePay', 'applePay'].forEach((paymentType) => {
            if (paymentType === 'paypal' || paymentType === 'paypalCredit') {
                dropinInstance.current!.updateConfiguration(paymentType as UpdatableConfigurationOption, 'amount', total);
            }
            if (paymentType === 'googlePay') {
                dropinInstance.current!.updateConfiguration(paymentType as UpdatableConfigurationOption, 'transactionInfo', {
                    totalPriceStatus: 'FINAL',
                    totalPrice: total,
                    currencyCode: 'USD',
                });
            }
            if (paymentType === 'applePay') {
                dropinInstance.current!.updateConfiguration(paymentType as UpdatableConfigurationOption, 'paymentRequest', {
                    total: {
                        label: 'HireAHelper',
                        amount: total,
                    },
                    requiredBillingContactFields: ['postalAddress'],
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropinInstance?.current, model.payNow]);

    useEffect(() => {
        const initBraintreeDropin = () => {
            Sentry?.addBreadcrumb({
                category: 'book-order',
                type: 'info',
                message: 'Initializing BrainTreeDropin',
            });
            console.debug('initBraintreeDropin');
            braintree.dropin
                .create({
                    authorization: btToken,
                    container: '#dropin-container',
                    paypalCredit: {
                        flow: 'vault',
                        amount: pageVm.grandTotalNumeric.toString(),
                        currency: 'USD',
                        buttonStyle: {
                            color: 'darkblue' as any,
                            shape: 'pill' as any,
                            size: 'responsive' as any,
                            height: 40,
                        },
                    },
                    paypal: {
                        flow: 'vault',
                        amount: pageVm.grandTotalNumeric.toString(),
                        currency: 'USD',
                        buttonStyle: {
                            color: 'gold' as any,
                            shape: 'pill' as any,
                            size: 'responsive' as any,
                            label: 'pay' as any,
                            height: 40,
                        },
                    },
                    applePay: {
                        displayName: 'HireAHelper',
                        paymentRequest: {
                            total: {
                                label: 'HireAHelper',
                                amount: pageVm.grandTotalNumeric.toString(),
                            },
                            requiredBillingContactFields: ['postalAddress'],
                        },
                        buttonStyle: 'black',
                        //buttonType: 'pay' as any,
                    },
                    googlePay: {
                        googlePayVersion: 2,
                        merchantId: googleMerchantID,
                        transactionInfo: {
                            totalPriceStatus: 'FINAL',
                            totalPrice: pageVm.grandTotalNumeric.toString(),
                            currencyCode: 'USD',
                        },
                        button: {
                            buttonSizeMode: 'fill',
                            buttonType: 'pay',
                        },
                        allowedPaymentMethods: [
                            {
                                type: 'CARD',
                                parameters: {
                                    // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
                                    billingAddressRequired: true,
                                    billingAddressParameters: {
                                        format: 'FULL',
                                    },
                                },
                            },
                        ],
                    } as googlePayCreateOptionsExtended,
                })
                .then((instance) => {
                    if (!instance) {
                        logErrorToSentry(new Error('BrainTree Dropin instance is undefined'));
                        globalSitewide.toastError('BrainTree Client Token Error', 'There was an issue with our payment processor. Please try again or contact support if the issue persists.');
                        return;
                    }
                    dropinInstance.current = instance;
                    instance.on('changeActiveView', (payload) => {
                        handleChangeActiveView(payload);
                    });
                    instance.on('paymentOptionSelected', (payload) => {
                        handlePaymentOptionSelected(payload);
                    });
                    instance.on('paymentMethodRequestable', (payload) => {
                        handlePaymentMethodRequestable(payload);
                    });
                    instance.on('noPaymentMethodRequestable', () => {
                        setFieldValue('paymentMethod', PaymentMethod.Unknown);
                        onChange({ paymentMethod: PaymentMethod.Unknown });
                    });

                    const btAnotherPaymentBtn = document.querySelector('[data-braintree-id="toggle"]');
                    btAnotherPaymentBtn!.addEventListener('click', handleClickAnotherPayment);
                    braintree.dataCollector
                        .create({
                            client: (instance as DropinExtended)._client,
                        })
                        .then((dataCollectorInstance) => {
                            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                            // to your server, e.g. by injecting it into your form as a hidden input.
                            console.log('Data Collector data', dataCollectorInstance);
                            onChange({ braintreeDeviceData: dataCollectorInstance.deviceData });
                        })
                        .catch((error2) => {
                            logErrorToSentry(error2);
                        });
                })
                .catch((error) => {
                    // This should not happen on production, but if it does, we want to know about it. Because of React.Strict, this will fire on dev on init.
                    logErrorToSentry(error);
                });
        };

        if (!dropinInstance.current) {
            initBraintreeDropin();
        }

        return () => {
            dropinInstance?.current?.teardown();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRequestPaymentMethod = () => {
        dropinInstance.current!.requestPaymentMethod((error, payload) => {
            if (error) {
                logErrorToSentry(error as Error);
                globalSitewide.toastError('There was an issue with your payment.', (error as Error).message);
            } else {
                paymentNonce.current = payload.nonce;
                handleSubmit();
            }
        });
    };

    const handleClickSubmit = () => {
        if (dropinInstance?.current) {
            handleRequestPaymentMethod();
        } else {
            logErrorToSentry(new Error('BrainTree Dropin instance is undefined'));
            globalSitewide.toastError('BrainTree Client Token Error', 'There was an issue with our payment processor. Please try again or contact support if the issue persists.');
        }
    };

    const handleClickAnotherPayment = () => {
        console.debug('User selected "choose another way to pay" in bt dropin: ', { oldPaymentMethod: window.BTPaymentType });
        Sentry?.captureEvent({
            level: 'warning',
            message: 'User selected "choose another way to pay" in bt dropin',
            extra: {
                oldPaymentMethod: window.BTPaymentType,
            },
        });
    };

    return (
        <div>
            <div className="pt-3 fs-mask" id="dropin-container" />
            {model.paymentMethod === PaymentMethod.CreditCard && (
                <>
                    <div className="mt-3">
                        <h5>Billing ZIP Code</h5>
                        <Row>
                            <Col xs={'auto'}>
                                <HahFormikField name="zip" parentName="paymentInfo.billingAddress" label="ZIP Code" autoComplete="postal-code" required />
                            </Col>
                        </Row>
                    </div>
                    <BillingContactInfo onChange={onChange} model={model} pageVm={pageVm} />
                </>
            )}
            <QuoteUrgencyBanner isFormSubmitting={isFormSubmitting} />
            {((paymentRequestable && model.paymentMethod !== PaymentMethod.CreditCard) || (model.paymentMethod === PaymentMethod.CreditCard)) && (
                <>
                    <TosCheckContainer paymentMethod={model.paymentMethod} />
                    <ActionButton type="submit" variant="" id="submit-btn" className="btn-lg d-block w-100 d-md-inline-block mt-3 rounded-pill btn-notification-dark-10 text-white" disabled={isFormSubmitting} onClickAction={handleClickSubmit}>
                        Book Now with {PaymentMethodDescription.get(model.paymentMethod)} <Icon icon={iconLibrary.faArrowRight} />
                    </ActionButton>
                </>
            )}
        </div>
    );
};

type TosCheckContainerProps = {
    paymentMethod: PaymentMethod;
};

export const TosCheckContainer = ({ paymentMethod }: TosCheckContainerProps) => {
    return (
        <FormLabel className="mt-3">
            <HahFormikCheckbox
                name="agreedToTos"
                required
                labelNode={
                    <div className="small">
                        By clicking the box, you agree to our <a href="https://www.porchmovinggroup.com/legal/" target="_blank">Terms of Service</a>. Once you authorize and confirm your payment with {PaymentMethodDescription.get(paymentMethod)} you will be taken to your confirmation page. Your reservation will not be made until this step is complete.
                    </div>
                }
            />
        </FormLabel>
    );
};
